<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        COMISIONES
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <CommissionsTable :headers="headers" :boards="boards">

       </CommissionsTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import CommissionsTable from '@/components/CommissionsTable.vue' 
export default {
  name: 'Commissions',
  components: {
    CommissionsTable
  },
  data() {
    return {
      headers: {
        commission: [
          {
            text: 'Proveedor',
            align: 'start',
            value: 'supplier_agents_commissionId.number',
          },
          {
            text: "Producto",
            align: 'center', 
            value: "productId.name"
          },
          {
            text: "Porcentaje",
            align: 'center', 
            value: "percent"
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
      },
      boards: {
        commission: 'agents_commission',
      }
    }
  },
}
</script>

<style>

</style>