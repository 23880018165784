<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="12">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Comisiones</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="commissionsSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.commission)"
                >
                  Crear Comisión
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.commission"
              :items="commission_records"
              :items-per-page="-1"
              multi-sort
              item-key="id"
              class=" rounded-xl"
              :search="commissionsSearch"
              :loading="loadingCommissionTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.commission)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.commission)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Commissions Form -->
    <v-row justify="center" >
      <v-form ref="createCommissionsForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogCommissions" persistent max-width="600px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Comisión ${createCommissionsForm.id}` : `Crear Comisión` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">                   
                    <v-autocomplete
                      class="text-caption"
                      v-model="createCommissionsForm.supplier_agents_commission"
                      item-value="id"
                      dense
                      :items="supplier_records"
                      item-text="number"
                      label="Proveedor"
                      required
                      :rules="[createFormRules.required]"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col xs="12" md="4">       
                    <v-autocomplete
                      class="text-caption"
                      v-model="createCommissionsForm.product"
                      item-value="id"
                      dense
                      :items="product_records"
                      item-text="name"
                      label="Producto"
                      required
                      :rules="[createFormRules.required]"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Porcentaje"
                      v-model="createCommissionsForm.percent"
                      type="number"
                      min="0"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.commission)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.commission)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.commission)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

   

    <!-- DIALOG DELETE COMISSIONS -->
    <v-dialog v-model="dialogDeleteCommissions" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar esta comisión?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.commission)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.commission)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  </div>
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingCommissionTable: false,
      //dialog delete
      dialogDeleteCommissions: false,
      //search
      commissionsSearch: '',
      //records
      commission_records: [],
      supplier_records: [],
      product_records: [],
      //form
      btnEdithVisible: false,
      valid: false,
      dialogCommissions: false,
      createCommissionsForm: {},
      //form rules
      createFormRules: {
        required: value => !!value || 'El campo es requerido.'
      },
      selectedRecord: null
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.commission) {
        if (!this.$refs.createCommissionsForm.validate()) {
          error = true
        } else {
          this.createCommissionsForm.percent = parseFloat(this.createCommissionsForm.percent)
          form = this.createCommissionsForm
        }
      }

      if (!error) {
        await this.$store.dispatch('verifyToken')
        
        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    editItem(item, board){
      // this.loa
      this.btnEdithVisible = true
      this.selectedRecord = item.id
      
      if (board == this.boards.commission) {
        this.createCommissionsForm.supplier_agents_commission = item.supplier_agents_commission
        this.createCommissionsForm.product = item.product
        this.createCommissionsForm.percent = item.percent
        this.dialogCommissions = true
      }
      
    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.commission) this.dialogDeleteCommissions = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.commission) this.dialogCommissions = true
    },
    closeForm(board) {
      if (board == this.boards.commission) {
        this.dialogCommissions = false
        this.$refs.createCommissionsForm.resetValidation()
        this.createCommissionsForm = {}
      }
      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.commission) this.dialogDeleteCommissions = false
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.commission) this.loadingCommissionTable = false
        
        if (board == this.boards.commission) this.commission_records = result.data.data
        if (board == 'supplier') this.supplier_records = result.data.data
        if (board == 'product') this.product_records = result.data.data
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  created() {
    this.getData(this.boards.commission)
    this.getData('supplier')
    this.getData('product')
  }
}
</script>